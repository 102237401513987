import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-congrates-modal',
  templateUrl: './congrates-modal.component.html',
  styleUrls: ['./congrates-modal.component.scss'],
})
export class CongratesModalComponent implements OnInit {

  constructor(private _modalCtrl: ModalController) { }

  ngOnInit() {}

  closeModal() {
    this._modalCtrl.dismiss();
  }

}
