import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageService } from "../common-services/storage-service.service.js";
import * as jsonFile from "../conatants/organization.json";

const ORG = jsonFile.buildFor;
const appFor = jsonFile.connectTo;
@Injectable({
  providedIn: "root",
})
export class MainAppSetting {
  public ORG = ORG;
  public userId;
  public appFor = appFor;
  // public storage = new Storage({})
  public token;
  public platform: string = "";

  constructor(private storageService: StorageService) {
    this.storageService.getDatafromIonicStorage("token").then((data) => {
      this.token = data;
    });
    this.storageService.getDatafromIonicStorage("user_id").then((data) => {
      this.userId = data;
    });
    // this.storageService.getDatafromIonicStorage('platform').then(data => {
    //     this.platform = data
    // })
  }

  getPlatform() {
    this.storageService.getDatafromIonicStorage("platform").then((data) => {
      this.platform = data;
    });
  }

  getHttpHeades() {
    const httpHeades = {
      // withCredentials : true,
      // Credentials:'include',
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
        Authorization: localStorage.getItem("token"),
      }),
      // withCredentials:true,
    };
    return httpHeades;
  }

  setTokenAferLogin(token) {
    this.token = token;
  }

  setPlatformAfterLogin(data: string) {
    this.platform = data;
  }

  getHttpHeadesWithToken() {
    const httpHeadesWithToken = {
      headers: new HttpHeaders({
        credentials: "include",

        "Content-Type": "application/json",
        authorization: window.localStorage.getItem("token"),
        "Access-Control-Allow-Credentials": "true",
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Credentials': 'true'
      }),
      withCredentials: true,
    };
    return httpHeadesWithToken;
  }

  getApi() {
    this.storageService.getDatafromIonicStorage("platform").then((data) => {
      this.platform = data;
    });

    let API = "";

    if (this.ORG == "Both") {
      if (window.localStorage.getItem("platform") == "rm") {
        if (this.appFor == "alpha") {
          API = "https://admin.grexter.in";
        } else if (this.appFor == "production") {
          API = "https://rentals.thehousemonk.com";
        }
      } else {
        if (this.appFor == "alpha") {
          API = "https://alpha.thehousemonk.com";
        } else if (this.appFor == "production") {
          API = "https://thehousemonk.com";
        }
      }
    } else if (this.ORG == "RM") {
      // window.localStorage.setItem('appSrc', 'rentals');
      this.storageService.storeDataToIonicStorage("appSrc", "rentals");

      if (this.appFor == "alpha") {
        // API = 'https://www.nowverifyit.com';
        // PWALink="https://pwa.nowverifyit.com"
        // API = 'https://develop.nowverifyit.com'; //charu
      } else if (this.appFor == "production") {
        //********** For Prod deployment **********
        API = 'https://www.nowverifyit.com';

        //********** For Dev deployment **********
        // API = "https://develop.nowverifyit.com";
        // if(window.localStorage.getItem('server')=='dev'){
        // //     // if(window.localStorage.getItem('server'))

        // //     API = 'https://develop.nowverifyit.com'; //charu
        // // }else if(window.localStorage.getItem('server')=='prod'){
        // //         // window.localStorage.clear();

        // //        API = 'https://www.nowverifyit.com';
        // // }else if(window.localStorage.getItem('server')=='dev1'){
        // //         // window.localStorage.clear();

        // //        API = 'https://dev1.nowverifyit.com';

        // // }else{
        // //     API = 'https://develop.nowverifyit.com'; //charu

        // // }
        // PWALink="https://pwa.nowverifyit.com"
      }
    } else if (this.ORG == "BM") {
      window.localStorage.setItem("appSrc", "building-management");
      this.storageService.storeDataToIonicStorage(
        "appSrc",
        "building-management"
      );

      if (this.appFor == "alpha") {
        API = "https://alpha.thehousemonk.com";
      } else if (this.appFor == "production") {
        API = "https://thehousemonk.com";
      } else {
        API = "http://localhost:3020";
      }
    }

    return API;
  }

  getPWALink() {
    let PWALink = "";
    if (this.ORG == "RM") {
      // window.localStorage.setItem('appSrc', 'rentals');
      this.storageService.storeDataToIonicStorage("appSrc", "rentals");

      if (this.appFor == "alpha") {
        //   return  PWALink='https://nowverifycap.web.app/'

          return  PWALink='https://pwa.nowverifyit.com/'
        // return (PWALink = "https://qa.nowverifyit.com/");
      } else if (this.appFor == "production") {
        //  return   PWALink='https://nowverifycap.web.app/'
          return  PWALink='https://pwa.nowverifyit.com/'
        // return (PWALink = "https://qa.nowverifyit.com/");
      }
    }
  }

  // public API = API;
  public HTTPHEADER = this.getHttpHeades();
  // public static API = API;
}
